// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['remembrancewalk'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

//  Add read more link
//$(".homeFeedBox2 .feedList").insertBefore("<a href='#'>Submit a story</a>");
$( "<a class='submitStoryLink' href='/blog/submit-a-story'>Submit a story</a>" ).insertBefore( ".homeFeedBox2 .feedList" );

// $allBlogsBtn = $('<div class="feedBtnWrapper"><a class="button buttonAllBlogs" href="/Pages/Events">Submit a story</a></div>');
// $('.homeFeedblogs').append( $allBlogsBtn );
